export const FEATURE_TOGGLE_V2_PROJECT_NAME = 'coupon-management';

export const TOGGLE_LIST = {
  CREATE_SPECIFIC_USER: '_admin_coupon_create_specific_user',
  UPLOAD_SPECIFIC_USER: '_admin_coupon_upload_specific_user',
  BRAZE_WALLETING: '_admin_coupon_braze_walleting',
  PRODUCT_RESTRICTION: '_admin_coupon_product_restriction',
  MGM: '_admin_coupon_mgm',
  MGM_MANAGE_COUPONS_BY_USER: '_admin_coupon_mgm_coupons_by_user',
  COUPON_ACTIVE_DEACTIVATE: '_admin_coupon_active_deactivate_by_user',
  COUPON_MANAGEMENT_DELETE: '_admin_coupon_management_delete',
  COUPON_MANAGEMENT_DELETE_BULK: '_admin_coupon_management_delete_bulk',
  COUPON_ACTIVE_DEACTIVATE_BULK: '_admin_coupon_active_deactivate_bulk',
  COUPON_NEW_COUPON_V2: '_admin_coupon_new_coupon_v2',
  MGM_RC: '_admin_coupon_mgm_coupons_rc',
  MGM_RWC: '_admin_coupon_mgm_coupons_rwc',
};
