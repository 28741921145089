import TicketIcon from '@/components/TicketIcon/TicketIcon';
import { COUPON_ROUTES } from '@/config/constants';
import { Home } from '@hexa-ui/icons';
import { useSidebar as useSideBarService } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';

export const HomeIcon = (): React.ReactElement => <Home />;
export const CouponListIcon = (): React.ReactElement => <TicketIcon />;

const useSidebar = () => {
  const { formatMessage } = useIntl();

  const items = [
    {
      id: 'home',
      title: formatMessage({ id: 'SIDEMENU.HOME' }),
      icon: HomeIcon,
      path: COUPON_ROUTES.HOME,
    },
    {
      id: 'coupons',
      title: formatMessage({ id: 'SIDEMENU.COUPON_MANAGEMENT' }),
      icon: CouponListIcon,
      path: COUPON_ROUTES.COUPON_MANAGEMENT,
    },
  ];

  return useSideBarService({ items, utils: [] });
};

export default useSidebar;
