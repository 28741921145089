import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  SIDEMENU: {
    HOME: 'Homepage',
    COUPON_MANAGEMENT: 'Coupon Management',
  },
  HOMEPAGE: {
    TITLE: 'Coupon campaigns',
    COUPON_MANAGEMENT: {
      TITLE: 'Coupon Management',
      DESCRIPTION: 'Manage coupon campaigns, create and edit coupons.',
    },
  },
  ACTIONS: {
    GO_BACK: 'Go back',
    BACK: 'Back',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    SEARCH: 'Search',
    APPLY: 'Apply',
    COPY: 'Copy',
    EDIT: 'Edit',
    SELECT: {
      DEFAULT: 'Select',
      ALL: 'Select All',
      ONE_OR_MORE: 'Select one or more',
    },
    REMOVE: 'Remove',
    YES: 'Yes',
    NO: 'No',
    CREATE_COUPON: 'Create coupon',
    SHOW_DETAILS: 'Show details',
    CREATE: 'Create',
    CONTINUE: 'Continue',
    PUBLISH: 'Publish',
    TRY_AGAIN: 'Try again',
    COPY_ENCRYPTION: 'Copy Encryption',
    ENCRYPT: 'Encrypt',
  },
  TOAST: {
    COUPON_CREATED: 'Coupon created',
    COUPON_UPDATED: 'Coupon updated',
    UNABLE_UPDATE_COUPON: 'Unable to update coupon.',
    UNABLE_GET_COUPON_DATA: 'Unable to get coupon data.',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panama',
    BO: 'Bolivia',
    DO: 'Dominican Republic',
    PY: 'Paraguay',
    PE: 'Peru',
    EC: 'Ecuador',
    ZA: 'South Africa',
    CO: 'Colombia',
    MX: 'Mexico',
    AR: 'Argentina',
    BR: 'Brazil',
  },
  VERSION: {
    VERSION_APP: 'Version',
    SERVER: 'Server',
    RIGHTS_RESERVED: 'Anheuser-Busch Inbev S.A. All rights reserved.',
  },
  UNAUTHORIZED_PAGE: {
    TITLE: 'Oops, access denied!',
    DESCRIPTION: "It seems you don't have permission to access this page.",
    ACTION: 'Go back to the homepage',
  },
  ENTITIES: {
    DELIVERY_CENTERS: {
      TITLE_PLURAL: 'Delivery Centers',
      TITLE_SINGLE: 'Delivery Center',
      ADDED: 'Delivery Center added',
      ADDED_QUANTITY: '{quantity} delivery centers added',
      ADDED_YET: 'No delivery centers added yet',
      SELECT_OR_UNSELECT: 'Select or unselect to assign a Delivery Center to the Coupon',
      SELECTED: 'Delivery centers selected',
      SELECT_FROM_THE_MENU: 'Select delivery centers from the menu above',
      COUPON_WILL_BE_ADDED:
        'Coupon will be added to the coupon wallet of consumers residing in regions served by elegible delivery centers.',
    },
    PRODUCTS: {
      TITLE_PLURAL: 'Products',
      TITLE_SINGLE: 'Product',
      BRAND: 'Brand',
      CATEGORY: 'Category',
      SELECTED_PRODUCTS: 'Selected products',
      SELECTED_CATEGORIES_BRANDS_PRODUCTS:
        'Select or unselect to assign or remove categories, brands or products to the coupon',
      THE_BENEFIT_OF_THIS_COUPON:
        'The benefit of this coupon will only apply to these products and not to the total order.',
      PRODUCTS_CONSIDERED_FOR_THE_COUPON_WILL_DISPLAY_HERE:
        'Products considered for the coupon will display here.',
    },
    USER: {
      ADD_COUPON_TO_WALLET: 'Add coupon to wallet',
      ADD_CSV_FILE: 'Upload CSV file',
      ADD_USERS: 'Add users',
      ADD_EMAILS: 'Add emails',
      ADD_USER_WALLET: 'Add to user wallet',
      USER_LIST: 'User list',
      USER_TAB: 'Users',
      USER_WALLET_DESCRIPTION:
        'By activating this, the coupon will appear in the coupon wallet of the user listed above automatically.',
      DESCRIPTION: 'Add users or leave blank to apply to all.',
      UPLOAD_A_CSV_FILE: 'Upload a CSV file',
      UPLOAD_A_CSV_FILE_DESCRIPTION:
        'Next step is to confirm whether the data uploaded is correct or not.',
      UPLOAD_CSV_FILE_MESSAGE: 'Drop CSV here to start uploading or',
      UPLOAD_CSV_FILE_BUTTON: 'Search files',
      CSV_FILE_UPLOADED: 'CSV File uploaded',
      CONFIRM_EMAILS_UPLOADED: 'Confirm emails uploaded',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_1: 'These data will be used to give users',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_2: 'discount coupons',
      INVALID_EMAILS: 'Invalid e-mails',
      INVALID_EMAILS_TOOLTIP:
        'Some emails are from unregistered users or banned domains and will always appear as invalid.',
      INVALID_EMAILS_DESCRIPTION_PART_1:
        'These are the invalid emails available in the loaded file.',
      INVALID_EMAILS_DESCRIPTION_PART_2: 'Please adjust the CSV file and resend it.',
      UPLOAD_THE_ADJUSTED_CSV_FILE: 'Upload the adjusted CSV file',
      UPLOAD_THE_ADJUSTED_CSV_FILE_DESCRIPTION:
        'Upload the adjusted CSV file, after correcting the invalid emails above.',
      INVALID_FILE:
        'Your file seems not to be a real CSV file. Upload an actual CSV file and try again.',
      THE_FILE_IS_EMPTY: 'The file is empty',
    },
    COUPONS: {
      TITLE_PLURAL: 'Coupons',
      TITLE_SINGLE: 'Coupon',
      PROPERTIES: {
        COUPON_NAME: 'Coupon name',
        COUPON_NAME_EXAMPLE: 'Example: Welcome to magic!',
        REDEEM_CODE: 'Redeem Code',
        REDEEM_CODE_EXAMPLE: 'Example: CORONA10',
        TYPE: {
          TITLE: 'Type',
          NOMINAL: 'Nominal',
          NOMINAL_EXAMPLE: 'Example: $5 off',
          PERCENTAGE: 'Percentage',
          PERCENTAGE_EXAMPLE: 'Example: 5% off',
          FREE_DELIVERY: 'Free delivery',
        },
        STATUS: 'Status',
        MAX_DISCOUNT: 'Max discount',
        PRODUCT_LEVEL_DISCOUNT: 'Product level discount',
        ELIGIBLE_DELIVERY: 'Eligible delivery centers',
        ELIGIBLE_DELIVERY_DESCRIPTION: 'You can specify eligible delivery centers for this coupon.',
        VALUE: 'Value',
        ORDER_MINIMUM: 'Order minimum',
        START_DATE: 'Start date',
        START_TIME: 'Start time',
        END_DATE: 'End date',
        END_TIME: 'End time',
        REDEMPTION_PER_CUSTOMER: 'Redemption per customer',
        REDEMPTION_PER_CUSTOMER_INFO:
          'If you apply this coupon only on first order, you are unable to set the redemption limit per user',
        REDEMPTION_BY_TOTAL_QUANTITY: 'Redemption by total quantity',
        REDEMPTION_BY_TOTAL_QUANTITY_INFO:
          "If you chosen to specify users by e-mail, you can't limit the redemption quantity",
        ADDITIONAL_INFORMATION: 'Additional information',
        ADDITIONAL_INFORMATION_EXAMPLE:
          'Example. Coupon can only be redeemed on available Corona products. Limit one per order. Discount will be applied to the lowest priced eligible item. Coupon cannot be applied with other discounts.',
        LEGAL_DETAILS: 'Legal details and specifications.',
        COUPON_PRICE: 'Coupon price',
        COUPON_VALUE: 'Coupon Value',
        PERCENTAGE_HINT: 'Until 100%',
        APPLY_COUPON: 'Apply coupon only on first order',
        APPLY_COUPON_TO_ALL_DELIVERY_CENTERS: 'Apply coupon to all delivery centers',
        FIRST_ORDER: 'First order',
        PREVIEW: {
          TITLE: 'Preview',
          EXAMPLE: {
            TITLE: 'Welcome to the magic!',
            DESCRIPTION:
              'Get the party started with Corona! Recieve $10 off your purchase of a 24 Pack.',
          },
        },
        COUNTRY: 'Country',
        EXPIRES: 'Expires',
        SELECT_THE_RESTRICTION_SETTINGS_YOU_WANT_BELOW:
          'Select the restriction settings you want below:',
        SET_RESTRICTION_BY_PRODUCT: 'Set restriction by Product?',
        SET_RESTRICTION_BY_PRODUCT_TOOLTIP:
          'The discount will be applied directly to the value of the restricted product, and not to the entire order value.',
        NO_RESTRICTION_BY_PRODUCT: 'No restriction by product',
        WITH_RESTRICTION_BY_PRODUCT: 'With restriction by product',
        SET_USER_RESTRICTION_BY_USER_EMAIL: 'Set user restriction by user email?',
        SET_USER_RESTRICTION_BY_USER_EMAIL_TOOLTIP:
          'If you restrict users, the coupon will only be applied to those.',
        NO_USER_RESTRICTIONS: 'No user restrictions',
        WITH_USER_RESTRICTIONS: 'With user restrictions',
        SELECT_THE_COUPON_TYPE: 'Select the coupon type:',
        RESTRICTIONS_DISABLE_HINT: 'If you choose to specify product, this field will be disabled',
        USE_BRAZE_TO_SEND_COUPON_TO_WALLET: 'Use Braze to send coupon to wallet',
        SEND_COUPON_TO_WALLET_BY_BRAZE: 'Send Coupon to Wallet by Braze',
      },
    },
    BRAZE: {
      ENCRYPTION_COMPLETE: 'Encryption Complete',
      ENCRYPTION_COPIED: 'Encryption Copied!',
      UNABLE_TO_ENCRYPT_TITLE: 'Unable to encrypt',
      GO_TO_BRAZE_AND_PASTE_ENCRYPTION: 'Go to Braze and Paste the encyption in the Editor.',
    },
  },
  COUPON_LIST: {
    TITLE: 'Coupon campaigns',
    PAGINATION: {
      QUANTITY_INDICATOR: 'of',
      PAGE_SIZE_OPTIONS: 'Items per page: {options}',
    },
    EMPTY: {
      TITLE: 'No coupon with the selected filters',
      MESSAGE: 'Try selecting other filters.',
    },
    LOADING: {
      TITLE: 'Loading',
      MESSAGE: "We are preparing the coupon's list for you.",
    },
    FILTERED: {
      TITLE: 'No Coupons yet',
      MESSAGE: 'The Coupons will be displayed here.',
      NOT_EXPIRED: 'Not expired',
    },
    ERROR: {
      400: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      401: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      403: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      404: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: "We couldn't perform this action. Try again.",
      },
      500: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      502: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      503: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      CUSTOM: {
        ENCRYPTION_CRITERIA_ERROR: 'Coupon does not follow encryption standards.',
        UNABLE_TO_ENCRYPT: 'An error has occurred in our system. Please try again later.',
      },
    },
  },
  FILTERS: {
    PLACEHOLDERS: {
      COUNTRY: 'Select a country',
      STATUS: 'Select a status',
      SEARCH_BY_COUPON_CODE: 'Search by coupon code',
      DATE: 'mm/dd/yyyy',
    },
  },
  STATUS: {
    ALL: 'All statuses',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EXPIRED: 'Expired',
  },
  FIELDS: {
    SELECT_AN_OPTION: 'Select an option',
    OPTIONAL: 'Optional',
    REQUIRED: {
      LABEL: 'Required',
      GENERIC: 'Required field',
      REDEEM_CODE: 'Enter characters without spaces',
      DATE: 'Select a date',
      ADDITIONAL_INFORMATION: 'Enter additional information',
      COUPON_VALUE: 'Enter a price',
    },
    FORMAT: {
      GENERIC: 'Invalid format',
    },
    RADIO: {
      LIMITED: 'Limited',
      UNLIMITED: 'Unlimited',
    },
  },
  LAYOUT: {
    GRID: 'Grid',
    LIST: 'List',
  },
  COUPON: {
    STEPS: {
      MAIN_INFORMATION: 'Main Information',
      MAIN_INFORMATION_DESCRIPTION: 'Define coupon name, type and period.',
      RESTRICTIONS: 'Restrictions',
      RESTRICTIONS_DESCRIPTION: 'Define coupon price and redemption limit.',
      TARGETING: 'Targeting',
      TARGETING_DESCRIPTION: 'Define eligibility: delivery centers, products and users.',
      EDIT_TARGETING: 'Edit targeting',
      SUMMARY_TITLE: 'Summary',
      SUMMARY_DESCRIPTION: 'Review and publish.',
      COUPON_CAMPAIGNS: 'Coupon campaigns',
      CREATE_COUPON: 'Create coupon',
    },
  },
  MONEY_MASK: {
    THOUSANDS_SEPARATOR: ',',
    RADIX: '.',
  },
};

export default enUS;
