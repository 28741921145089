import PageHeader from '@/components/PageHeader/PageHeader';
import TicketIcon from '@/components/TicketIcon/TicketIcon';
import { COUPON_ROUTES } from '@/config/constants';
import { Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Card, Container } from './CouponHomePage.styles';

const CouponHomePage = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const breadcrumbs = [
    {
      name: formatMessage({ id: 'HOMEPAGE.TITLE' }),
      active: true,
      href: COUPON_ROUTES.HOME,
    },
  ];

  return (
    <Container data-testid="home-page">
      <PageHeader title={formatMessage({ id: 'HOMEPAGE.TITLE' })} breadcrumbs={breadcrumbs} />
      <Card
        data-testid="card-coupon-management"
        onClick={() => {
          navigate(COUPON_ROUTES.COUPON_MANAGEMENT);
        }}
        border="medium"
        elevated="large"
      >
        <TicketIcon id="icon-coupon-management" />
        <div>
          <Heading size="H4">{formatMessage({ id: 'HOMEPAGE.COUPON_MANAGEMENT.TITLE' })}</Heading>
          <Paragraph size="small">
            {formatMessage({ id: 'HOMEPAGE.COUPON_MANAGEMENT.DESCRIPTION' })}
          </Paragraph>
        </div>
      </Card>
    </Container>
  );
};

export default CouponHomePage;
