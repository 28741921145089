import { Card as _Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  paddingTop: '$6',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: '$4',
});

export const Card = styled(_Card, {
  flex: 1,
  padding: '$6',
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'row !important',
  alignItems: 'center',
  svg: { marginRight: '$2', width: 48, height: 48 },
  h4: { fontSize: '$4' },
  p: { color: 'rgb(117, 117, 117)' },
  '#icon-coupon-management': { fill: '#006EDB' },
  '&:hover': { boxShadow: '$8' },
});
