import { FileValidated } from '@hexa-ui/components';
import { IEnforcementUser, RestrictionByUserType } from './user.types';

export interface ICouponMainInfo {
  firstStep: IFirstStep;
  secondStep: ISecondStep;
  thirdStep: IThirdStep;
  userRestrictionFile?: FileValidated[];
  usersRestrictionFileName?: string;
}

export interface IFirstStep {
  couponCode: string;
  couponName: string;
  additionalInformation: string;
  specifyUsers?: RestrictionByUserType;
  type: string | undefined;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export interface ISecondStep {
  couponValue: string;
  orderMinimum: string;
  maxDiscount: string;
  firstOrder: boolean;
  totalPerUser?: number;
  totalNumber?: number;
}

export interface IThirdStep {
  users?: Array<string>;
  deliveryCentersIds: Array<string> | undefined;
  insertToOriginalWallet: boolean;
  insertToUserWallet: boolean;
  itemsIds: Array<string> | undefined;
  outputDiscountType: OutputDiscountType | undefined;
  enforcementUser?: Array<IEnforcementUser>;
  usersFileName?: string;
  file?: FileValidated[];
}

export enum LimitEnum {
  limited = 'Limited',
  unlimited = 'Unlimited',
}

export enum CouponTypeEnum {
  nominal = 'Nominal',
  percentage = 'Perentage',
  freeDelivery = 'Free Delivery',
}

export type OutputDiscountType = 'ORDER_LEVEL' | 'LINE_ITEM';

export enum OutputDiscountTypeEnum {
  ORDER_LEVEL = 'ORDER_LEVEL',
  LINE_ITEM = 'LINE_ITEM',
}

export interface IEmail {
  code: number;
  email: string;
}

export interface ICategories {
  categories: Array<ICategory>;
}

export interface ICategory {
  id: string;
  name: string;
  categoryLevel: number;
  sortOrder: number;
  image: ImageCategory;
  enabled: boolean;
  parentId: string;
  urlKey: string;
  categories: Array<ICategory>;
  items: Array<Item>;
  link: string;
  imageAltText: string;
  restricted: boolean;
}

export interface Item {
  itemDetails: ItemDetails | null;
  sortOrder: number;
  vendorId: string;
  vendorItemId: string;
}

export interface ItemDetails {
  id: string;
  brandId: string;
  brandName: string;
  createdDate: string;
  description: string;
  itemImage: string;
  itemName: string;
  sku: string;
  vendorId: string;
  vendorItemId: string;
}

export interface ImageCategory {
  iconImageURL: string;
  mainImageURL: string;
}
