import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  NEW_COUPON: {
    STEPS: {
      COUPON_LEVEL: 'Coupon level',
      COUPON_TYPE: 'Type',
      COUPON_SUBTYPE: 'Sub-type',
      COUPON_MAIN_INFORMATION: 'Main information',
      COUPON_VALUE: 'Coupon value',
      RESTRICTIONS: 'Restrictions',
    },
    COUPON_LEVEL: {
      TITLE: 'What is the level of the coupon are we creating today?',
      ITEM_LEVEL: {
        TITLE: 'Item level',
        DESCRIPTION: 'Coupons that require items to be used (DDC Restriction).',
      },
      ORDER_LEVEL: {
        TITLE: 'Order level',
        DESCRIPTION: 'Coupons that have no links to items and are for the cart.',
      },
    },
    COUPON_TYPE: {
      TITLE: {
        P1: 'Nice, an',
        P2: '! Of what type?',
        ITEM_LEVEL: 'Item Level coupon',
        ORDER_LEVEL: 'Order Level coupon',
      },
      NOMINAL: {
        TITLE: 'Nominal coupon',
        DESCRIPTION: 'Example: $ 5 off',
      },
      PERCENTAGE: {
        TITLE: 'Percentage coupon',
        DESCRIPTION: 'Example: % 5 off',
      },
      BACK_BUTTON: 'Go back to level selection',
    },
    WARNINGS: {
      COUPON_AMOUNT_GREATER_ERROR_MESSAGE:
        "This coupon's value is greater than usual. Make sure you are filling the details carefully.",
    },
    COUPON_SUBTYPE: {
      TITLE: {
        P1: 'How to best describe this',
        ITEM_LEVEL: {
          NOMINAL: 'Nominal Item Level coupon',
          PERCENTAGE: 'Percentage Item Level coupon',
        },
        ORDER_LEVEL: {
          NOMINAL: 'Nominal Order Level coupon',
          PERCENTAGE: 'Percentage Order Level coupon',
        },
      },
      ITEMS: {
        PRODUCT_RESTRICTION: {
          TITLE: 'Product Restriction',
          DESCRIPTION: 'Coupon with product restriction for cart and item.',
        },
        GENERAL: {
          TITLE: 'General',
          DESCRIPTION:
            'Coupon with monetary value, without value restrictions (just enter 1 as the minimum value). The GE operator, Subtotal (can be changed) is used.',
        },
        ITEM: {
          TITLE: 'Item',
          DESCRIPTION: 'Coupons that require items to be used (DDC Restriction).',
        },
      },
      SELECT_BUTTON: 'Select',
      BACK_BUTTON: 'Go back to type selection',
    },
    COUPON_PREVIEW: {
      TITLE: 'Coupon Preview',
      COUPON_TITLE: 'Coupon',
    },
    MAIN_INFORMATION: {
      CHANGE: 'Change',
      TITLE: 'Main Information',
      DESCRIPTION: 'Coupon description',
      VALIDATION_PERIOD: 'Validation period',
      ITEM_LEVEL: 'Item Level',
      WITH: 'with',
      COUPON_CODE: 'Coupon code',
      COUPON_NAME: 'Coupon name',
      ADDITIONAL_INFORMATION: 'Additional information',
      START_DATE: 'Start date',
      START_TIME: 'Start time',
      END_DATE: 'End date',
      END_TIME: 'End time',
      COUPON_TYPE: {
        NOMINAL: 'Nominal Coupon',
        PERCENTAGE: 'Percentage Coupon',
      },
      COUPON_SUBTYPE: {
        PRODUCT_RESTRICTION: 'Product Restriction',
        GENERAL: 'General',
        ITEM: 'Item',
      },

      USER_RESTRICTION: {
        TITLE: 'User restriction',
        NO_USER_RESTRICTIONS: 'No user restrictions',
        SET_USER_RESTRICTIONS: 'Set user restrictions',
        SET_WITH_BRAZE: 'Set it with Braze',
      },

      WARNINGS: {
        COUPON_CODE_ERROR_MESSAGE: 'Enter characters without spaces',
        ADDITIONAL_INFORMATION_ERROR_MESSAGE: 'Enter additional information',
        DATE_ERROR_MESSAGE: 'Set a proper date',
        START_TIME_ERROR_MESSAGE: 'Set an starting time',
        END_TIME_ERROR_MESSAGE: 'Set an ending time',
      },
    },
    RESTRICTIONS: {
      TITLE: 'Restrictions',
      MANDATORY: '(Mandatory)',
      ITEMS: {
        USERS: 'Users',
      },
      ACTIONS: {
        SAVE: 'Save',
        CANCEL: 'Cancel',
      },
      DELIVERY_CENTERS: {
        TITLE: 'Eligible delivery centers',
        APPLY_TO_ALL: 'Apply to all delivery centers',
        ADD_COUPON_TO_WALLETS: 'Add coupon to wallets in the selected regions',
        SEARCH_INPUT: 'Search',
        SELECTED_DELIVERY_CENTERS: 'Selected delivery centers',
        SELECTED_DELIVERY_CENTERS_DESCRIPTION:
          'You can specify eligible delivery centers for this coupon.',
      },
      PRODUCTS: {
        TITLE: 'Products',
        MINIMUM_AMOUNT: {
          LABEL: 'Minimum Amount',
          PLACEHOLDER: 'Enter hint text',
        },
        MAXIMUM_AMOUNT: {
          LABEL: 'Maximum amount',
          PLACEHOLDER: 'Enter hint text',
        },
      },
    },
    REVIEW: {
      MAIN_INFORMATION: {
        COUPON_NAME: 'Coupon name',
        COUPON_CODE: 'Coupon code',
        START_DATE: 'Start date',
        START_TIME: 'Start time',
        END_DATE: 'End date',
        END_TIME: 'End time',
        ADDITIONAL_INFORMATION: 'Additional information',
      },
      VALUE: {
        TYPE: 'Type',
        COUPON_VALUE: 'Coupon value',
        ORDER_MINIMUM: 'Order minimum',
        MAX_DISCOUNT: 'Max discount',
        APPLY_COUPON_ONLY_ON_FIRST_ORDER: 'Apply coupon only on first order',
        REDEMPTION_PER_CUSTOMER: 'Redemption per customer',
        REDEMPTION_PER_CUSTOMER_TOOLTIP:
          'Number of times the coupon can be redeemed by each consumer.',
        REDEMPTION_BY_TOTAL_QUANTITY: 'Redemption by total quantity',
        REDEMPTION_BY_TOTAL_QUANTITY_TOOLTIP:
          'Number of times the coupon can be redeemed in total.',
      },
      RESTRICTION: {
        ELEGIBLE_DELIVERY_CENTERS: 'Eligible delivery centers',
        COUNTRY: 'Country',
        DELIVERY_CENTER: 'Delivery center',
        PRODUCTS: 'Products',
        SET_USER_RESTRICTION_BY_USER_EMAIL: 'Set user restriction by user email',
      },
    },
    ERRORS: {
      COUPON_NAME_LONGER_THAN_USUAL: 'The coupon name is longer than usual',
      COUNPON_ALREADY_IN_USE: 'The coupon code is already in use',
      COUPON_VALIDATION_PERIOD_SMALLER: 'The validation period is smaller than usual',
    },
  },
  PRODUCT_SELECTION: {
    TITLE: 'Selected products',
    DESCRIPTION: 'Products considered for the coupon will display here.',
  },
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  SIDEMENU: {
    HOME: 'Homepage',
    COUPON_MANAGEMENT: 'Coupon Management',
    MGM: 'Member Get Member (MGM)',
  },
  HOMEPAGE: {
    TITLE: 'Coupon campaigns',
    COUPON_MANAGEMENT: {
      TITLE: 'Coupon Management',
      DESCRIPTION: 'Manage coupon campaigns, create and edit coupons.',
    },
    MGM: {
      TITLE: 'Member Get Member (MGM)',
      DESCRIPTION:
        'Manage referral (RC) and reward (RWC) coupon settings and making them available to all TaDa zones.',
    },
  },
  ACTIONS: {
    ADD_T0_BLOCKLIST: 'Add to blocklist',
    GO_BACK: 'Go back',
    BACK: 'Back',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    SEARCH: 'Search',
    APPLY: 'Apply',
    COPY: 'Copy',
    EDIT: 'Edit',
    SELECT: {
      DEFAULT: 'Select',
      ALL: 'Select All',
      ONE_OR_MORE: 'Select one or more',
    },
    REMOVE: 'Remove',
    YES: 'Yes',
    NO: 'No',
    CREATE_COUPON: 'Create coupon',
    SHOW_DETAILS: 'Show details',
    CREATE: 'Create',
    CONTINUE: 'Continue',
    PUBLISH: 'Publish',
    TRY_AGAIN: 'Try again',
    COPY_ENCRYPTION: 'Copy Encryption',
    ENCRYPT: 'Encrypt',
  },
  TOAST: {
    COUPON_CREATED: 'Coupon created',
    COUPON_UPDATED: 'Coupon updated',
    UNABLE_UPDATE_COUPON: 'Unable to update coupon.',
    UNABLE_GET_COUPON_DATA: 'Unable to get coupon data.',
    GENERIC_ERROR: 'An error occurred while trying to perform this action.',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panama',
    BO: 'Bolivia',
    DO: 'Dominican Republic',
    PY: 'Paraguay',
    PE: 'Peru',
    EC: 'Ecuador',
    ZA: 'South Africa',
    CO: 'Colombia',
    MX: 'Mexico',
    AR: 'Argentina',
    BR: 'Brazil',
  },
  VERSION: {
    VERSION_APP: 'Version',
    SERVER: 'Server',
    RIGHTS_RESERVED: 'Anheuser-Busch Inbev S.A. All rights reserved.',
  },
  UNAUTHORIZED_PAGE: {
    TITLE: 'Oops, access denied!',
    DESCRIPTION: "It seems you don't have permission to access this page.",
    ACTION: 'Go back to the homepage',
  },
  ENTITIES: {
    DELIVERY_CENTERS: {
      TITLE_PLURAL: 'Delivery Centers',
      TITLE_SINGLE: 'Delivery Center',
      ADDED: 'Delivery Center added',
      ADDED_QUANTITY: '{quantity} delivery centers added',
      ADDED_YET: 'No delivery centers added yet',
      SELECT_OR_UNSELECT: 'Select or unselect to assign a Delivery Center to the Coupon',
      SELECTED: 'Delivery centers selected',
      SELECT_FROM_THE_MENU: 'Select delivery centers from the menu above',
      COUPON_WILL_BE_ADDED:
        'Coupon will be added to the coupon wallet of consumers residing in regions served by elegible delivery centers.',
    },
    PRODUCTS: {
      TITLE_PLURAL: 'Products',
      TITLE_SINGLE: 'Product',
      BRAND: 'Brand',
      CATEGORY: 'Category',
      SELECTED_PRODUCTS: 'Selected products',
      SELECTED_CATEGORIES_BRANDS_PRODUCTS:
        'Select or unselect to assign or remove categories, brands or products to the coupon',
      THE_BENEFIT_OF_THIS_COUPON:
        'The benefit of this coupon will only apply to these products and not to the total order.',
      PRODUCTS_CONSIDERED_FOR_THE_COUPON_WILL_DISPLAY_HERE:
        'Products considered for the coupon will display here.',
    },
    USER: {
      ADD_COUPON_TO_WALLET: 'Add coupon to wallet',
      ADD_CSV_FILE: 'Upload CSV file',
      ADD_USERS: 'Add users',
      ADD_EMAILS: 'Add emails',
      ADD_USER_WALLET: 'Add to user wallet',
      USER_LIST: 'User list',
      USER_TAB: 'Users',
      USER_WALLET_DESCRIPTION:
        'By activating this, the coupon will appear in the coupon wallet of the user listed above automatically.',
      DESCRIPTION: 'Add users or leave blank to apply to all.',
      UPLOAD_A_CSV_FILE: 'Upload a CSV file',
      UPLOAD_A_CSV_FILE_DESCRIPTION:
        'Next step is to confirm whether the data uploaded is correct or not.',
      UPLOAD_CSV_FILE_MESSAGE: 'Drop CSV here to start uploading or',
      UPLOAD_CSV_FILE_BUTTON: 'Search files',
      CSV_FILE_UPLOADED: 'CSV File uploaded',
      CONFIRM_EMAILS_UPLOADED: 'Confirm emails uploaded',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_1: 'These data will be used to give users',
      CONFIRM_EMAILS_UPLOADED_DESCRIPTION_PART_2: 'discount coupons',
      INVALID_EMAILS: 'Invalid e-mails',
      INVALID_EMAILS_TOOLTIP:
        'Some emails are from unregistered users or banned domains and will always appear as invalid.',
      INVALID_EMAILS_DESCRIPTION_PART_1:
        'These are the invalid emails available in the loaded file.',
      INVALID_EMAILS_DESCRIPTION_PART_2: 'Please adjust the CSV file and resend it.',
      UPLOAD_THE_ADJUSTED_CSV_FILE: 'Upload the adjusted CSV file',
      UPLOAD_THE_ADJUSTED_CSV_FILE_DESCRIPTION:
        'Upload the adjusted CSV file, after correcting the invalid emails above.',
      INVALID_FILE:
        'Your file seems not to be a real CSV file. Upload an actual CSV file and try again.',
      THE_FILE_IS_EMPTY: 'The file is empty',
    },
    COUPONS: {
      TITLE_PLURAL: 'Coupons',
      TITLE_SINGLE: 'Coupon',
      PROPERTIES: {
        COUPON_NAME: 'Coupon name',
        COUPON_NAME_EXAMPLE: 'Example: Welcome to magic!',
        REDEEM_CODE: 'Redeem Code',
        REDEEM_CODE_EXAMPLE: 'Example: CORONA10',
        TYPE: {
          TITLE: 'Type',
          NOMINAL: 'Nominal',
          AMOUNT_OFF: 'Nominal',
          NOMINAL_EXAMPLE: 'Example: $5 off',
          PERCENTAGE: 'Percentage',
          PERCENTAGE_EXAMPLE: 'Example: 5% off',
          PERCENT_OFF: 'Percentage',
          FREE_DELIVERY: 'Free delivery',
        },
        STATUS: 'Status',
        MAX_DISCOUNT: 'Max discount',
        PRODUCT_LEVEL_DISCOUNT: 'Product level discount',
        ELIGIBLE_DELIVERY: 'Eligible delivery centers',
        ELIGIBLE_DELIVERY_DESCRIPTION: 'You can specify eligible delivery centers for this coupon.',
        VALUE: 'Value',
        ORDER_MINIMUM: 'Order minimum',
        START_DATE: 'Start date',
        START_TIME: 'Start time',
        END_DATE: 'End date',
        END_TIME: 'End time',
        REDEMPTION_PER_CUSTOMER: 'Redemption per customer',
        REDEMPTION_PER_CUSTOMER_INFO:
          'If you apply this coupon only on first order, you are unable to set the redemption limit per user',
        REDEMPTION_BY_TOTAL_QUANTITY: 'Redemption by total quantity',
        REDEMPTION_BY_TOTAL_QUANTITY_INFO:
          "If you chosen to specify users by e-mail, you can't limit the redemption quantity",
        ADDITIONAL_INFORMATION: 'Additional information',
        ADDITIONAL_INFORMATION_EXAMPLE:
          'Example: Coupon can only be redeemed on available Corona products. Limit one per order. Discount will be applied to the lowest priced eligible item. Coupon cannot be applied with other discounts.',
        LEGAL_DETAILS: 'Legal details and specifications.',
        COUPON_TYPE: 'Coupon type',
        COUPON_PRICE: 'Coupon price',
        COUPON_VALUE: 'Coupon Value',
        PERCENTAGE_HINT: 'Until 100%',
        APPLY_COUPON: 'Apply coupon only on first order',
        APPLY_COUPON_TO_ALL_DELIVERY_CENTERS: 'Apply coupon to all delivery centers',
        FIRST_ORDER: 'First order',
        PREVIEW: {
          TITLE: 'Preview',
          EXAMPLE: {
            TITLE: 'Welcome to the magic!',
            DESCRIPTION:
              'Get the party started with Corona! Recieve $10 off your purchase of a 24 Pack.',
          },
        },
        COUNTRY: 'Country',
        EXPIRES: 'Expires',
        EXPIRE: 'Expire',
        SELECT_THE_RESTRICTION_SETTINGS_YOU_WANT_BELOW:
          'Select the restriction settings you want below:',
        SET_RESTRICTION_BY_PRODUCT: 'Set restriction by Product?',
        SET_RESTRICTION_BY_PRODUCT_TOOLTIP:
          'The discount will be applied directly to the value of the restricted product, and not to the entire order value.',
        NO_RESTRICTION_BY_PRODUCT: 'No restriction by product',
        WITH_RESTRICTION_BY_PRODUCT: 'With restriction by product',
        SET_USER_RESTRICTION_BY_USER_EMAIL: 'Set user restriction by user email?',
        SET_USER_RESTRICTION_BY_USER_EMAIL_TOOLTIP:
          'If you restrict users, the coupon will only be applied to those.',
        NO_USER_RESTRICTIONS: 'No user restrictions',
        WITH_USER_RESTRICTIONS: 'With user restrictions',
        SELECT_THE_COUPON_TYPE: 'Select the coupon type:',
        RESTRICTIONS_DISABLE_HINT: 'If you choose to specify product, this field will be disabled',
        USE_BRAZE_TO_SEND_COUPON_TO_WALLET: 'Use Braze to send coupon to wallet',
        SEND_COUPON_TO_WALLET_BY_BRAZE: 'Send Coupon to Wallet by Braze',
        USAGE_LIMIT: 'Usage limit',
        USAGE_LIMIT_PER_USER: 'Usage limit per user',
        UNLIMITED_USAGE: 'Unlimited',
      },
      STATUS_UPDATE: {
        SUCCESS: 'Coupon status successfully changed',
        FAIL: 'Failed to change coupon status',
      },
      COUPON_DETAILS: 'Coupon Details',
    },
    BRAZE: {
      ENCRYPTION_COMPLETE: 'Encryption Complete',
      ENCRYPTION_COPIED: 'Encryption Copied!',
      UNABLE_TO_ENCRYPT_TITLE: 'Unable to encrypt',
      GO_TO_BRAZE_AND_PASTE_ENCRYPTION: 'Go to Braze and Paste the encyption in the Editor.',
    },
  },
  DELETE_COUPON: {
    BUTTON: 'Delete coupon',
    MODAL: {
      TITLE: 'Delete coupon',
      TEXT: "You are deleting the coupon from a specific DDC. We won't be able to retrieve any information after deletion.",
      CONFIRM: 'Delete coupon',
    },
    TOAST_ERROR: 'Unable to delete coupon',
  },
  COUPON_DELETED_PAGE: {
    TITLE: 'This coupon was deleted',
    DESCRIPTION: 'Go back to the coupon campaigns',
    ACTION: 'Go Back',
  },
  REFERRAL_REWARD_COUPON: {
    COUPON_VALUE_LABEL: 'Coupon price',
    BREAD_CRUMB_NAME: 'Change parameters',
    DESCRIPTION: 'Description',
    EXPIRATION_PERIOD: 'Expiration period',
    MAIN_INFORMATION: 'Main information',
    PLURAL_EXPIRATION: 'months after creation',
    REFERRAL_PAGE_TITLE: 'RC Coupons',
    RESTRICTIONS: 'Restrictions',
    REWARD_PAGE_TITLE: 'RWC Coupons',
    SINGULAR_EXPIRATION: 'month after creation',
    TITLE: 'Title',
    ORDER_MINIMUM_HINT: 'Pay attention to the minimum order value for each country.',
    ERROR: {
      TITLE: 'Oops, something went wrong!',
      DESCRIPTION: 'Currently unable to show this information. Please try again later.',
      LOAD_TEMPLATE: 'Failed to load the coupon.',
      UPDATE_TEMPLATE: 'Unable to update coupon.',
    },
    SUCCESS: {
      COUPON_UPDATED: 'Coupon updated.',
    },
  },

  COUPON_LIST: {
    TITLE: 'Coupon campaigns',
    PAGINATION: {
      QUANTITY_INDICATOR: 'of',
      PAGE_SIZE_OPTIONS: 'Items per page: {options}',
    },
    EMPTY: {
      TITLE: 'No coupon with the selected filters',
      MESSAGE: 'Try selecting other filters.',
    },
    LOADING: {
      TITLE: 'Loading',
      MESSAGE: "We are preparing the coupon's list for you.",
    },
    FILTERED: {
      TITLE: 'No Coupons yet',
      MESSAGE: 'The Coupons will be displayed here.',
      NOT_EXPIRED: 'Not expired',
    },
    ERROR: {
      400: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      401: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      403: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      404: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: "We couldn't perform this action. Try again.",
      },
      500: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      502: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      503: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
      CUSTOM: {
        ENCRYPTION_CRITERIA_ERROR: 'Coupon does not follow encryption standards.',
        UNABLE_TO_ENCRYPT: 'An error has occurred in our system. Please try again later.',
      },
    },
    SELECTION_ITEMS: {
      SELECT_GRID: 'Select',
      EMPTY_SELECTION: 'Showing {total} results',
      SELECTED_ITEMS: '{selecteds} of {total} selected',
      DESELECT_ALL: 'Deselect all',
      ACTIVATE: 'Activate',
      INACTIVATE: 'Inactivate',
    },
    ACTIVATE_COUPONS: {
      BUTTON: 'Active',
      MODAL: {
        TITLE: 'Active {amount} coupons?',
        TEXT: 'Activating the coupon will make it usable again.',
        BUTTON_CONFIRM: 'Active Coupons',
      },
      TOAST_SUCCESS: '{amount, plural, one {# coupon} other {# coupons}} successfully activated',
      TOAST_INFO: 'Some of the selected coupons are already active',
      TOAST_ERROR:
        'Unable to activate {amount, plural, one {# coupon} other {# coupons}} due to expiration date',
      TOAST_ERROR_BY_AMOUNT:
        '{amount, plural, one {# coupon} other {# coupons}} have not changed due to expiration date',
    },
    DEACTIVATE_COUPONS: {
      BUTTON: 'Inactivate',
      MODAL: {
        TITLE: 'Inactivate {amount} coupons?',
        TEXT: 'Inactivating the coupon results in it not being usable until it is active again.',
        BUTTON_CONFIRM: 'Inactivate Coupons',
      },
      TOAST_SUCCESS: '{amount, plural, one {# coupon} other {# coupons}} successfully inactivated',
      TOAST_INFO: 'Some of the selected coupons are already inactive',
      TOAST_ERROR:
        'Unable to deactivate {amount, plural, one {# coupon} other {# coupons}} due to expiration date',
      TOAST_ERROR_BY_AMOUNT:
        '{amount, plural, one {# coupon} other {# coupons}} have not changed due to expiration date',
    },
    DELETE_COUPONS: {
      BUTTON: 'Delete',
      MODAL: {
        TITLE: 'Delete {quantity} coupons',
        TEXT: "Are you sure you want to delete the coupons? We won't be able to retrieve any information after deletion. Make sure you are deleting the proper coupons.",
        BUTTON_CONFIRM: 'Delete Coupon',
      },
      TOAST_SUCCESS: '{quantity} coupons successfully deleted',
      TOAST_ERROR: 'Failed to delete coupons',
    },
  },
  FILTERS: {
    PLACEHOLDERS: {
      COUNTRY: 'Select a country',
      STATUS: 'Select a status',
      SEARCH_BY_COUPON_CODE: 'Search by coupon code',
      DATE: 'mm/dd/yyyy',
    },
  },
  STATUS: {
    ALL: 'All statuses',
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
    INACTIVE: 'Inactive',
    PENDING: 'Pending',
    SCHEDULED: 'Scheduled',
    USED: 'used',
  },
  FIELDS: {
    SELECT_AN_OPTION: 'Select an option',
    RANGE_NUMBER: 'Has to be between {min} and {max}',
    OPTIONAL: 'Optional',
    REQUIRED: {
      LABEL: 'Required',
      GENERIC: 'Required field',
      REDEEM_CODE: 'Enter characters without spaces',
      DATE: 'Select a date',
      ADDITIONAL_INFORMATION: 'Enter additional information',
      COUPON_VALUE: 'Enter a price',
      LONG_TEXT: 'Enter up to {max} characters',
    },
    FORMAT: {
      GENERIC: 'Invalid format',
    },
    RADIO: {
      LIMITED: 'Limited',
      UNLIMITED: 'Unlimited',
    },
  },
  LAYOUT: {
    GRID: 'Grid',
    LIST: 'List',
  },
  COUPON: {
    STEPS: {
      MAIN_INFORMATION: 'Main Information',
      MAIN_INFORMATION_DESCRIPTION: 'Define coupon name, type and period.',
      RESTRICTIONS: 'Restrictions',
      RESTRICTIONS_DESCRIPTION: 'Define coupon price and redemption limit.',
      TARGETING: 'Targeting',
      TARGETING_DESCRIPTION: 'Define eligibility: delivery centers, products and users.',
      EDIT_TARGETING: 'Edit targeting',
      SUMMARY_TITLE: 'Summary',
      SUMMARY_DESCRIPTION: 'Review and publish.',
      COUPON_CAMPAIGNS: 'Coupon campaigns',
      CREATE_COUPON: 'Create coupon',
    },
  },
  MONEY_MASK: {
    THOUSANDS_SEPARATOR: ',',
    RADIX: '.',
  },
  MGM: {
    BREADCRUMBS: 'Manage MGM',
    TITLE: 'Manage MGM parameters',
    SELECT_TYPE: 'Select the type of the coupon to manage the parameters',
    RC: {
      TITLE: 'Referral coupon (RC)',
      DESCRIPTION:
        'Referral coupon, with no use limit, valid only for the first purchase, which has no expiration date.',
    },
    RWC: {
      TITLE: 'Reward coupon (RWC)',
      DESCRIPTION:
        'Reward coupon, received by the referrer after the referral completes their first purchase.',
    },
    MANAGE_COUPONS_BY_USER: 'Manage coupons by user',
  },
  MGM_MANAGEMENT: {
    PAGE_TITLE: 'Manage MGM Coupons',
    PAGE_SUBTITLE: 'Select a country and insert an email or coupon code to search',
    MANAGE_BLOCKLIST: 'Manage blocklist',
    BLOCKLIST: 'Blocklist',
    BLOCKED: 'Blocked',
    UNBLOCKED: 'Unblocked',
    HINT: "You can only block or unlock the RC owner's coupon",
    OWNER: 'Owner',
    COUPON_TOOLTIP: 'It is necessary to adjust the dates before activating the coupon.',
    TABLE: {
      EMAIL: 'Email',
      COUPON_CODE: 'Coupon code',
      MGM_TYPE: 'RC/RWC',
      COUPON_TYPE: 'Type',
      COUPON_STATUS: 'Status',
      COUPON_VALUE: 'Value',
      EXPIRATION: 'Expiration',
      BLOCK_LIST: 'Blocklist',
      BLOCK_DATE: 'Block date',
      BLOCKED: 'Blocked',
    },
    BLOCK_MODAL: {
      TITLE: 'Confirmation',
      TEXT_P1: 'Are you sure you want to',
      TEXT_BLOCK: 'block',
      TEXT_UNBLOCK: 'unblock',
    },
    SEARCH_PLACEHOLDER: 'Search by email or coupon code',
    ADD_COUPON_CODE: 'Add coupon codes',
    UPLOAD_FILE: 'Upload CSV file',
    BLOCKLIST_TITLE: 'Add coupon codes to the blocklist',
    BLOCKLIST_HINT_CODE: 'Add the coupon codes you want to add to the blocklist.',
    BLOCKLIST_HINT_FILE:
      'Upload a spreadsheet to indicate which users you want to add to the blocklist.',
  },
  LAST_ALTERATIONS: {
    TITLE: 'Last Alterations',
    TABLE: {
      USER: 'User',
      UPDATED_IN: 'Updated in',
      MODIFICATION: 'Modification',
      FULL_LOG: 'See full log',
    },
    DIFFERENCES: {
      DISCOUNT_TYPE: 'Discount type',
      COUPON_TYPE: 'Coupon type',
      COUPON_VALUE: 'Coupon value',
      MINIMUM_ORDER: 'Minimum order',
      MAX_DISCOUNT: 'Maximum discount',
      FIRST_ORDER: 'First order',
      TITLE: 'Title',
      DESCRIPTION: 'Description',
      MINIMUM_VALUE_FOR_ORDER: 'Minimum value for order',
    },
    FULL_LOG: {
      TITLE: 'Full Log',
      OLD_JSON: 'Previous',
      NEW_JSON: 'New',
      CLOSE_BUTTON: 'Close',
    },
    EMPTY: {
      TITLE: 'Oops...',
      DESCRIPTION: "We haven't receive data from this country",
    },
  },
};

export default enUS;
